<template>
  <div class="home">
    <div id="demo1"></div>

    <h3>内容预览</h3>

    <el-card class="box-card">
      <div class="item" v-html="this.$store.getters.editorContent"></div>
    </el-card>
  </div>
</template>

<script>
import wangEditor from "wangeditor";

export default {
  props:["aid"],
  data() {
    return {
      editor: null,
    };
  },
  mounted() {
    const editor = new wangEditor(`#demo1`);
    editor.config.uploadImgHeaders = {
      Authorization: localStorage.getItem("Authorization"),
    };
    // 图片上传
    editor.config.uploadImgServer = '/adminApi/upload'//配置 server 接口地址
    editor.config.uploadFileName = 'files'//上传文件参数 类似于 input 带参 name
    editor.config.uploadImgHooks = {
        // 图片上传并返回了结果，想要自己把图片插入到编辑器中
        // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
        customInsert: function (insertImgFn, result) {
            // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
            for (let i = 0; i < result.data.length; i++) {
                insertImgFn(result.data[i])
            }
        }
    }
    //网络视频
    editor.config.showLinkVideo = true
    // 自定义检查插入的链接
    editor.config.linkCheck = function (text, link) {
        if (typeof (link) != undefined && link != '') {
            var strRegex = '^((https|http|ftp|rtsp|mms)?://)'
                + '?(([0-9a-z_!~*\'().&amp;=+$%-]+: )?[0-9a-z_!~*\'().&amp;=+$%-]+@)?' //ftp的user@
                + '(([0-9]{1,3}.){3}[0-9]{1,3}' // IP形式的URL- 199.194.52.184
                + '|' // 允许IP和DOMAIN（域名）
                + '([0-9a-z_!~*\'()-]+.)*' // 域名- www.
                + '([0-9a-z][0-9a-z-]{0,61})?[0-9a-z].' // 二级域名
                + '[a-z]{2,6})' // first level domain- .com or .museum
                + '(:[0-9]{1,4})?' // 端口- :80
                + '((/?)|' // a slash isn't required if there is no file name
                + '(/[0-9a-z_!~*\'().;?:@&amp;=+$,%#-]+)+/?)$';
            var reg = new RegExp(strRegex);
            if (!reg.test(link.toLowerCase())) {
                return "跳转链接格式错误!";
            }
        }
        // 以下情况，请三选一
        // 1. 返回 true ，说明检查通过
        return true
        // // 2. 返回一个字符串，说明检查未通过，编辑器会阻止链接插入。会 alert 出错误信息（即返回的字符串）
        // return '链接有 xxx 错误'
    }
    editor.config.onchange = (newHtml) => {
      this.$store.commit("editorContent", newHtml);
    };

    editor.create();
    this.editor = editor;
    this.editor.txt.html(this.$store.getters.editorContent); // 重新设置编辑器内容
  },
  watch: {
    aid: function () {
      this.editor.txt.html(this.$store.getters.editorContent);
    },
  },
  beforeDestroy() {
    this.editor.destroy();
    this.editor = null;
  },
};
</script>

<style scoped>
.home {
  margin-top: 50px;
}
h3 {
  margin: 30px 0 15px;
}

.item {
  width: 100%;
  min-height: 200px;
}
</style>